<template>
    <v-container>
        <div class="mt-10">
            <h3>{{ $t("message.title-success-register") }}</h3>
            <v-divider style="background-color: grey; margin-bottom: 10px"></v-divider>

            <div>
                <p>
                    {{ $t("message.desc-text-success-register1") }}
                    <br/><br/>
                    {{ $t("message.desc-text-success-register2") }}
                </p>
            </div>

            <div class="d-flex flex-row justify-center align-center me-0 pt-6 pb-6 mt-10" tile>
                <div class="m-2">
                    <v-btn color="#E93B81" type="button" @click="$router.push('/login')" height="45" width="250">
                    <span style="color: white">{{ $t("message.btn-halaman-login") }}</span>
                    <v-icon color="white">
                        mdi-chevron-right
                    </v-icon>
                    </v-btn>
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
export default {
    name: "SuccessRegister"
}
</script>

<style>

</style>